import React from 'react';

const NoMatch = () => (
	<div>
		<h1 style={{color: 'white'}}>404 Page not found!</h1>
		<a href='/'>Return to Homepage</a>
	</div>
)

export default NoMatch;
